import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useEffect, useState } from 'react'
import { getDb } from '../firebase/helpers'
import { Users } from '../firebase/firestore/Users'

export const useUsersData = (uid: string | null) => {
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState<Users | undefined>(undefined)
  const [data, dataLoading] = useDocumentData<Users>(
    uid === null ? null : getDb().collection('users').doc(uid)
  )

  useEffect(() => {
    if (!dataLoading) {
      setUserData(data)
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [dataLoading])

  return [userData, loading] as const
}
