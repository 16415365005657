import { Sex } from './Sex'
import { VoiceChat } from './VoiceChats'
import { GameTitle } from './GameTitle'
import { Platform } from './Platform'
import firebase from 'firebase/app'

export const COLLECTION_NAME_USERS = 'users'
export const COLLECTION_NAME_FRIENDS = 'friends'
export const COLLECTION_NAME_FRIEND_REQUESTS = 'friendRequests'
export const COLLECTION_NAME_BLOCKING_USERS = 'blockingUsers'
export const COLLECTION_NAME_BLOCKED_BY_USERS = 'blockedByUsers'
export type Users = {
  userId: string
  name: string
  platforms: Array<Platform> //Deprecated
  userPlatforms: Record<string, boolean>
  gameTitles: Array<GameTitle>
  sex: Sex
  voiceChats: Array<VoiceChat>
  profileImgUrl: string
  playTimeWeekDayBegin: number
  playTimeWeekDayEnd: number
  playTimeWeekEndBegin: number
  playTimeWeekEndEnd: number
  statement: string
  twitchChannelUrl: string
  youtubeChannelUrl: string
  tiktokUrl: string //https://www.tiktok.com/@tomonari_t
  twitterUid: string
  youtubeVideoUrls: string[]
  birthYear?: number
  userRatings?: number
  numOfReviews?: number
  aveRatings?: number // 4.8888888 など
  isPremium?: boolean
  membershipDurationPeriod?: number
  createAt: firebase.firestore.Timestamp
}

export interface FriendRequest {
  createAt: firebase.firestore.Timestamp
  profileImgUrl: string
  userId: string
  userName: string
}
