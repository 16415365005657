import styled from '@emotion/styled'
import React from 'react'
import { colors } from '@/styles/theme'
import { Nav } from '@/components/molecules/Nav'
import { NavPC } from '@/components/molecules/NavPC/NavPC'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from '@/firebase/helpers'
import { useUsersData } from '@/hooks/useUsersData'
export const WIDTH = 520 // px

// サイズに応じた出し分けを行う
export const GlobalNav: React.FC = () => {
  const [user] = useAuthState(getAuth())
  const [userData] = useUsersData(user?.uid ?? null)
  return (
    <>
      <PCHeader>
        <NavPC currentUser={userData} hasUnreadNotifications={false} />
      </PCHeader>
      <MobileFooter>
        <Nav userData={userData} hasUnreadNotifications={false} />
      </MobileFooter>
    </>
  )
}

const PCHeader = styled.div`
  display: block;
  position: sticky;
  top: 0;
  background-color: ${colors.bg.primary};
  z-index: 10;
  @media (max-width: 500px) {
    display: none;
  }
`

const MobileFooter = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`
